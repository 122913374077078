<template>
  <button
    v-if="store.getters.hasChauffeurOrOnderaannemerPermission && !store.getters.isKantoorPermission"
    class="btn small px-2"
    :class="{ success: status, warn: !status }"
    @click="toggleSlaapStatus"
  >
    <i class="far" :class="{ 'fa-play-circle ': status, 'fa-pause-circle': !status }"></i>
    {{ status ? 'Wakker' : 'Slapen' }}
  </button>
</template>

<script setup>
import { useStore } from 'vuex'
import { useVModel } from '@vueuse/core'

import useApi from '@/hooks/useApi'

const props = defineProps({
  modelValue: Boolean,
})
const emit = defineEmits(['update:modelValue'])
const status = useVModel(props, 'modelValue', emit, { deep: true, passive: true })

const store = useStore()
const api = useApi()

const toggleSlaapStatus = () => {
  api('CHAUFFEUR_SLAAPSTATUS', { value: !status.value }).then(() => {
    status.value = !status.value
  })
}
</script>
